
import React, { useEffect } from 'react';
import { Address } from '../libs/types';
import { GoogleMap, Marker } from '@react-google-maps/api';
import TextField from '@material-ui/core/TextField'
import ReactGoogleMapLoader from 'react-google-maps-loader'
import Autocomplete from "react-google-autocomplete"

const GOOGLE_MAPS_API_KEY = 'AIzaSyDAy7a04XIcVUP4fE9v8TVWpvfQRaTXdVA';


export function GetGMap(address: Address, width: number, height: number) {
  const [map, setMap] = React.useState<any>(null)

  const onLoad = React.useCallback(function callback(map: any) {
    setMap(map)
  }, [])
  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null)
  }, [])
  const options = {
    streetViewControl: false,
  }

  if (!address.location) {
    return (<></>)
  }

  return (
    <ReactGoogleMapLoader
      params={{
        key: GOOGLE_MAPS_API_KEY, // Define your api key here
        libraries: "places", // To request multiple libraries, separate them with a comma
      }}
      render={(googleMaps: any) => googleMaps && (

        <GoogleMap
          key={address.location.lat}
          mapContainerStyle={width != -1 ? {
            width: `${width}px`,
            height: `${height}px`,
          } : {

            height: `400px`,
            width: '100%'
          }}
          center={address.location}
          zoom={15}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={options}
        >
          {<Marker
            label={address.text}
            position={address.location}

          />}
          <></>
        </GoogleMap>)}
    />

  )
}



export function GetMultiPinGMap(label:string[],addresses: Address[], width: number, height: number) {
  const [map, setMap] = React.useState<any>(null)

  const onLoad = React.useCallback(function callback(map: any) {
    setMap(map)
  }, [])
  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null)
  }, [])
  const options = {
    streetViewControl: false,
  }
  useEffect(() => {
    if (map) {
      var bounds = new window.google.maps.LatLngBounds();
      for(var i = 0; i < addresses.length; i++) {
        bounds.extend( new window.google.maps.LatLng(addresses[i].location.lat, addresses[i].location.lng));
      }
      map.fitBounds(bounds)
    }
  }, [addresses, map])

  if (addresses.length == 0) {
    return (<></>)
  }

  return (
    <ReactGoogleMapLoader
      params={{
        key: GOOGLE_MAPS_API_KEY, // Define your api key here
        libraries: "places", // To request multiple libraries, separate them with a comma
      }}
      render={(googleMaps: any) => googleMaps && (

        <GoogleMap
          key={addresses[0].location.lat}
          mapContainerStyle={width != -1 ? {
            width: `${width}px`,
            height: `${height}px`,
          } : {

            height: `400px`,
            width: '100%'
          }}
          center={addresses[0].location}
          //zoom={15}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={options}
        >
          {addresses.map((address,i) =>
              <Marker
              label={label[i]}
              position={address.location}
  
            />

          )
          }
          <></>
        </GoogleMap>)}
    />

  )
}


export function GmapsAutocomplete(placeHolder: string, onSelect: (a: Address) => void,initialvalue :string="") {
  const [isSet, setisSet] = React.useState<any>(initialvalue.length>0?true:false)
  return (
    <Autocomplete
      defaultValue={initialvalue}
      placeholder={placeHolder}
      apiKey={GOOGLE_MAPS_API_KEY}
      onPlaceSelected={(place) => {
        var res = {
          location: {
            lat: place.geometry?.location!.lat()!,
            lng: place.geometry?.location!.lng()!
          },
          text: place.formatted_address || ""
        }
        onSelect(res)
        if (place.geometry){
          setisSet(true)
        }else{
          setisSet(false)
        }
      }}
      
      options={{ types: ['address'] }}
      style={{
        width: '100%',
        height: '100%',
        lineHeight: '1.1876em',
        letterSpacing: '0.00938em',
        borderRadius: '4px',
        boxSizing: 'border-box',
        padding: '18.5px 14px',
        fontWeight: 400,
        fontSize: '1rem',
        color: isSet?'black':"#f44336",
        borderStyle: 'solid',
        borderColor: isSet?'#a9a9a9cf':"#f44336",
        borderWidth: 'thin',
      }}
    />)
}
