
import { Stack } from "@mui/material";

import { t } from "i18next";
import { MRT_ColumnDef } from "material-react-table";
import { useContext, useState } from "react";
import { Company, FirmWare, DeviceTypeLabels, Device } from "../../libs/types";
import { Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { CompaniesContext } from "../../contexts/companyContext";

export interface DeviceRepresentation {
    company: Company | null
    device: Device
}

interface CreateModalProps {
    columns: MRT_ColumnDef<DeviceRepresentation>[];
    onClose: () => void;
    onSubmit: (values: Device) => void;
    open: boolean;
    title: string
    confirmButtonCaption: string
    firmwares: FirmWare[]
}


//example of creating a mui dialog modal for creating new rows

export const CreateModal = ({
    open,
    columns,
    onClose,
    onSubmit,
    title,
    confirmButtonCaption,
    firmwares
}: CreateModalProps) => {
    const companies = useContext(CompaniesContext).getAllCompanies()
    const [values, setValues] = useState<any>(() =>
        columns.reduce((acc, column) => {
            acc[column.accessorKey ?? ''] = '';
            return acc;
        }, {} as any),
    );

    const
        handleSubmit = () => {
            values["id"] = values["device.id"]
            values["companyID"] = selectedCompany
            values["type"] = selectedType
            values["firmwareID"] = selectedFirmware
            onSubmit(values);
            onClose();
        };
    const [selectedType, setselectedType] = useState(DeviceTypeLabels[0])
    const [selectedCompany, setselectedCompany] = useState(companies.length > 0 ? companies[0].id : "")
    const [selectedFirmware, setselectedFirmware] = useState(firmwares.length > 0 ? firmwares[0].id : "")

    return (
        <Dialog open={open}>
            <DialogTitle /*textAlign="center"*/>{title}</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        {columns.map((column) => {
                            switch (column.accessorKey!!) {

                                case "device.user":
                                    break;
                                case "device.lastSeen":
                                    break;
                                case "device.type":
                                    return (<><Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedType}
                                        label="Select Type"
                                        onChange={(e) => {

                                            setValues({ ...values, ["deviceType"]: e.target.value })
                                                ; setselectedType(e.target.value as string)
                                        }}
                                    >
                                        {DeviceTypeLabels.map(label =>
                                            <MenuItem value={label}>{t(label)}</MenuItem>)
                                        }
                                    </Select></>)
                                case "company.name":
                                    return (<><Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedCompany}
                                        label="Select Company"
                                        onChange={(e) => {

                                            setValues({ ...values, ["companyID"]: e.target.value })
                                                ; setselectedCompany(e.target.value as string)
                                        }}
                                    >
                                        {companies.map(company =>
                                            <MenuItem value={company.id}>{t(company.name)}</MenuItem>)
                                        }
                                    </Select></>)
                                case "device.firmwareID":
                                    return (<><Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedFirmware}
                                        label="Select Firmware"
                                        onChange={(e) => {

                                            setValues({ ...values, ["firmwareID"]: e.target.value })
                                                ; setselectedFirmware(e.target.value as string)
                                        }}
                                    >
                                        {firmwares.map(fw =>
                                            <MenuItem value={fw.id}>{t(fw.name)}</MenuItem>)
                                        }
                                    </Select></>)
                                case "device.threshold":
                                    return (<TextField
                                        label={column.header}
                                        name={column.accessorKey}
                                        onChange={(e) =>
                                            setValues({ ...values, ["threshold"]: e.target.value })
                                        }
                                    />)
                                default:
                                    return (<TextField
                                        label={column.header}
                                        name={column.accessorKey}
                                        onChange={(e) =>
                                            setValues({ ...values, [e.target.name]: e.target.value })
                                        }
                                    />)

                            }

                        })}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions /*sx={{ p: '1.25rem' }}*/>
                <Button onClick={onClose}>{t("cancel")}</Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                    {confirmButtonCaption}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
