import { useContext, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { Company, FormulaGroup, Grid as PriceGrid } from '../libs/types'
import MaterialReactTable from 'material-react-table';
import type { MRT_ColumnDef } from 'material-react-table';
import {
  Add as AddIcon,
  Cancel as CancelIcon,
  Edit as EditIcon,
  Check as CheckIcon,
  Save as SaveIcon,
  NotInterested as NotInterestedIcon,
  Delete as DeleteIcon,
  DateRange as DateRangeIcon,
  Calculate as CalculateIcon
} from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@material-ui/core/IconButton'
import { Button, Card, CardContent, TextField, Tooltip } from '@material-ui/core'
import { createGrid, list, updateGrid } from '../service/grid'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import toast, { Toaster } from 'react-hot-toast';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { NewGridModal } from '../components/grid/newGridModal'
import { NewPeriodModal } from '../components/grid/newPeriodModal'
import { flatten, unflatten } from 'flat'
import { PeriodEditor } from '../components/periodsSetter'
import bgF2m from '../ressources/backgroundf2m.png';
import SimulatorPopup from '../components/grid/pricingsimulator'
import PricingCalendar from '../components/grid/pricingCalendar'
import { AuthContext } from '../contexts/authContext'
import Services from '../service/services'
import { Trans, useTranslation } from 'react-i18next';
import { CompaniesContext } from '../contexts/companyContext'


const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    height: 'calc(100vh - 7vh)',
    backgroundImage: `url(${bgF2m})`,
    backgroundSize: 'cover',
    backgroundColor: "#1e0046"
  },
  title: {
    textAlign: 'center',
  },
  spaced: {
    marginLeft: '25px!important',
    marginRight: '25px!important',
  },
  session: {
    width: '80vw',
    overflow: 'auto',
    overflowWrap: 'break-word',
    fontSize: '16px',
  },
  spacedTop: {
    marginTop: "30px"
  },
  hero: {
    width: '100%',
    background: 'rgb(220,220,220)',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vvw',
    backgroundColor: 'white',
    border: '2px solid #000',
  },
  twoItem: {
    width: '100%',
    display: 'inline-block'
  },
  threeItem: {
    width: '45%',
    marginLeft: '20px',
    display: 'inline-block'
  },
  error: {
    display: 'inline-block',
    color: "red"
  },
  verticalCenter: {
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  highLine: {
    lineHeight: "50px",
    display: 'inline'
  },
  tab: {
    marginLeft: "50px",
  },
  oneLineButton: {
    display: "inline-flex"
  },
}))


export default function GridPage() {
  const { t } = useTranslation()
  const classes = useStyles()
  const companiesMap = useContext(CompaniesContext).companyMap
  const GridColumns = useMemo<MRT_ColumnDef<PriceGrid>[]>(() => gridColumnFactory(t, companiesMap), [])
  var [statedata, setstatedata] = useState(null as unknown as PriceGrid[]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [SimulatorOpen, setSimulatorOpen] = useState(false);
  const [calendarOpen, setcalendarOpen] = useState(false);

  const auth = useContext(AuthContext)
  const services = new Services(auth.sessionInfo?.accessToken || "")
  const isFleetManager = auth.sessionInfo?.fleetManagerProfile
  var refresh = async () => {
    var d = await services.listGrids()
    if (isFleetManager) {
      d = d.filter((g: PriceGrid) => g.companyID == auth.sessionInfo?.companyID)
    }
    d.sort((a, b) => a.provider.localeCompare(b.provider) || a.name.localeCompare(b.name))
    setstatedata(d)
  }

  useEffect(() => {
    refresh()
  }, [])

  return (

    <Grid className={classes.root} container direction="column" alignItems="center">
      <PricingCalendar
        isOpen={calendarOpen}
        onclose={() => setcalendarOpen(false)}
      />
      <SimulatorPopup
        isOpen={SimulatorOpen}
        onclose={() => setSimulatorOpen(false)}
        availableGrids={statedata || []}

      />
      <Grid container spacing={1} >
        <Grid container item xs={12} direction="column" >
          <Box maxWidth={'100%'} style={{ backgroundColor: "white" }} className={[classes.spaced, classes.spacedTop].join(" ")} >
            {!isFleetManager ? (<></>) : (
              <>
              <Typography className={[classes.spaced, classes.spacedTop].join(" ")}>
                <Trans i18nKey="fleetGridExplanation" components={{ 1: <br /> }} />
              </Typography>
              <br/>
              </>
              )
            }
            <MaterialReactTable
              muiTableHeadCellProps={{
                //simple styling with the `sx` prop, works just like a style prop in this example
                sx: {
                  fontWeight: 'bold',
                },
              }}
              initialState={{ density: "compact" }}
              enableRowActions={false}
              columns={GridColumns}
              data={statedata || []}
              enableRowSelection={false}
              enableExpandAll={false}
              enableColumnActions={false}
              enableColumnOrdering={false}
              enableHiding={false}
              enableDensityToggle={false}
              renderDetailPanel={row => {
                return DetailPanel(statedata[row.row.index], async () => { await refresh() })
              }}
              enableGlobalFilter={true} 
              renderTopToolbarCustomActions={() => (
                <>
                  <Grid container direction="row" spacing={1}>
                    <Grid item xs={4} className={classes.verticalCenter}>
                      <Button
                        color={"secondary"}
                        className={classes.oneLineButton}
                        variant="contained"
                        startIcon={<CalculateIcon />}
                        onClick={() => setSimulatorOpen(true)}>
                       {t("costTool").toUpperCase()}
                      </Button>
                      &nbsp;
                      <Button
                        color={"primary"}
                        className={classes.oneLineButton}
                        variant="contained"
                        startIcon={<DateRangeIcon />}
                        onClick={() => setcalendarOpen(true)}>
                          {t("calendar").toUpperCase()}
                      </Button>
                      <Tooltip title="Ajouter une grille">
                        <IconButton onClick={() => setCreateModalOpen(true)} >
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={7} className={classes.verticalCenter}></Grid>
                  </Grid>
                </>
              )}
            />
            <br />
          </Box>
        </Grid>
      </Grid>
      <NewGridModal
        allowPublicGrids={!isFleetManager}
        key={Math.random()}
        columns={GridColumns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={async (c) => { await services.createGrid(c); refresh() }}
      />
    </Grid >
  )
}

const gridColumnFactory = (t: (s: string) => string, companies: Map<string, Company>): MRT_ColumnDef<PriceGrid>[] => [
  {
    accessorKey: 'provider',
    header: 'Fournisseur',
    minSize: 200

  },
  {
    accessorKey: 'name',
    header: 'Offre',
    minSize: 300
  },
  {
    accessorKey: 'companyID',
    header: 'Client',
    Cell: ({ cell }) => {
      const cid = cell.row.original.companyID
      return (
        <span>
          {cid ? companies.get(cid)?.name : " - "}
        </span>
      )

    },
  },
  {
    accessorKey: 'billNeeded',
    header: 'Facture requise',
    minSize: 100
    ,
    Cell: ({ cell }) => {
      return cell.row.original.billNeeded ?
        <CheckIcon /> : <NotInterestedIcon />
    },
  },
  {
    accessorKey: 'isFixedPrice',
    header: 'Prix fixes',
    minSize: 100
    ,
    Cell: ({ cell }) => {
      return cell.row.original.isFixedPrice ?
        <CheckIcon /> : <NotInterestedIcon />
    },
  },
  {
    accessorKey: 'isDualTarif',
    Cell: ({ cell }) => {
      return cell.row.original.isDualTarif ?
        <CheckIcon /> : <NotInterestedIcon />
    },
    header: 'HC/HP',
    minSize: 100
  },
  {
    accessorKey: 'hasSpecialDays',
    header: 'Jours spéciaux',
    minSize: 100,
    Cell: ({ cell }) => {
      return cell.row.original.hasSpecialDays ?
        <CheckIcon /> : <NotInterestedIcon />
    },
  },
  {
    accessorKey: 'fixedHCperiods',
    header: 'Heures creuses fixes',
    minSize: 100,
    Cell: ({ cell }) => {
      return cell.row.original.fixedHCperiods ?
        <CheckIcon /> : <NotInterestedIcon />
    },
  }

]


interface FormulaTextFieldProps {
  label: string;
  formulaKey: string;
  formulaValue: string;
  editMode: boolean;
  width?: string;
  handleChange: (key: string, value: string) => void;
}

const FormulaTextField: React.FC<FormulaTextFieldProps> = ({
  editMode,
  label,
  formulaKey,
  width,
  formulaValue,
  handleChange,
}) => {
  return (
    <>
      <TextField
        style={{ width: width ? width : '100% ', marginRight: '10px' }}
        variant="outlined"
        label={label}
        disabled={!editMode}
        value={formulaValue}
        onChange={(e) => {
          handleChange(formulaKey, e.target.value);
        }}
      />
    </>
  )
};


interface FormulaGridDetail {
  grid: PriceGrid,
  index: number,
  dualTarif: boolean,
  specialDays: boolean,
  fixedHCperiods: boolean,
  onSubmit: (group: FormulaGroup) => void
}

const FomulaGroupDetail = ({ grid,
  index,
  dualTarif,
  specialDays,
  fixedHCperiods,
  onSubmit }: FormulaGridDetail) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const [editMode, seteditMode] = useState(false);
  const [formulaGroup, setformulaGroup] = useState<FormulaGroup>(grid.formulaGroups[index]);
  var allDaysFormula = formulaGroup.allDaysFormula

  const handleChange = (field: string, value: any) => {
    if (field == "HCperiods") {
      const res = [] as Date[][]
      for (const period of value) {
        res.push([new Date(period[0]), new Date(period[1])])
      }
      formulaGroup.HCperiods = res
    }

    var f: any = flatten(formulaGroup)
    f.end = formulaGroup.end
    f.start = formulaGroup.start
    if (field != "HCperiods") {
      f[field] = value
    }
    setformulaGroup(unflatten(f))
  }

  return (
    <>

      <Card style={{ maxWidth: '100%', width: '99%', margin: "10px", display: 'inline-block' }} >
        <CardContent>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={editMode ? 6 : 3} >
              {editMode ?
                (<>
                  <Typography className={classes.highLine}>Periode: Du</Typography>
                  <KeyboardDatePicker
                    disableToolbar
                    className={[classes.verticalCenter, classes.spaced].join(" ")}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="start"
                    label="Début"
                    value={formulaGroup.start}
                    onChange={(date: Date | null) => {
                      if (date != null) {
                        handleChange("start", date)
                      }
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  <Typography className={classes.highLine}>Au</Typography>
                  <KeyboardDatePicker
                    className={[classes.verticalCenter, classes.spaced].join(" ")}
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="end"
                    label="Fin"
                    value={formulaGroup.end}
                    onChange={(date: Date | null) => {
                      if (date != null) {
                        handleChange("end", date)
                      }
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  <Button
                    color={"secondary"}
                    className={classes.oneLineButton}
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={async () => {
                      await onSubmit(formulaGroup)
                      seteditMode(false)
                    }}
                  >
                    {t("save")}
                  </Button>
                  <IconButton onClick={() => {
                    seteditMode(!editMode)
                  }}>


                    <Tooltip title="Annuler">
                      <CancelIcon />
                    </Tooltip>
                  </IconButton>
                </>) :
                (<>
                  <Typography className={classes.highLine}>Periode: Du {formulaGroup.start.toLocaleDateString()} au {formulaGroup.end.toLocaleDateString()}</Typography>
                  <IconButton onClick={() => {
                    seteditMode(!editMode)
                  }}>
                    <Tooltip title="Editer la période">
                      <EditIcon />
                    </Tooltip>
                  </IconButton>
                </>)
              }
            </Grid>
            <Grid item xs={1} className={classes.verticalCenter}>
              {editMode ?
                (<>
                  <TextField
                    className={classes.twoItem}
                    variant="outlined"
                    label={'TVA (%)'}
                    value={formulaGroup.TVA}
                    onChange={(e) => {
                      handleChange("TVA", e.target.value)
                    }}
                  />
                </>) : (
                  <>
                    <Typography>TVA: {formulaGroup.TVA} %</Typography>
                  </>
                )
              }
            </Grid>
            {
              allDaysFormula ? (<>

                <Grid item xs={3} className={classes.verticalCenter}>
                  <FormulaTextField
                    editMode={editMode}
                    label={"Formule"}
                    formulaKey={`formulas.mon.formula`}
                    formulaValue={formulaGroup.formulas["mon"].formula as string}
                    handleChange={handleChange} />
                </Grid>

                <Grid item xs={2} className={classes.verticalCenter}>
                  <label>
                    <Checkbox
                      checked={allDaysFormula}
                      disabled={!editMode}
                      onChange={(e) => {
                        handleChange("allDaysFormula", e.target.checked)
                      }} />
                    Formule valide tous les jours
                  </label>
                </Grid>
              </>) : (<><Grid item xs={2} className={classes.verticalCenter}>
                <label>
                  <Checkbox
                    checked={allDaysFormula}
                    disabled={!editMode}
                    onChange={(e) => {
                      handleChange("allDaysFormula", e.target.checked)
                    }} />
                  Formule valide tous les jours
                </label>
              </Grid></>)
            }

            <Grid item xs={12} ><span>&nbsp;</span> </Grid>
          </Grid>
          {
            allDaysFormula ?
              (<></>)
              :
              <>
                <Grid container direction="row" spacing={0}>
                  <Grid item xs={12} className={classes.verticalCenter}>
                    {["mon", "tue", "wed", "thu", "fri", "sat", "sun"].map((day) => {
                      return (
                        <FormulaTextField
                          editMode={editMode}
                          width='calc(100% /8)'
                          label={day}
                          formulaKey={`formulas.${day}.formula`}
                          formulaValue={formulaGroup.formulas[day as "mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "sun"].formula as string}
                          handleChange={handleChange}
                        />
                      );
                    })
                    }
                  </Grid>
                </Grid>
              </>
          }{editMode ? (
            <>
              <br />
              <Grid item xs={12} >
                <b>
                  Symboles disponibles
                </b>
                : HP {grid.isDualTarif ? ", HC " : ""}{grid.isFixedPrice ? grid.isDualTarif ? ", HPF , HCF ," : ", HPF " : ""}, TOTAL


              </Grid>
            </>) : (<></>)

          }


          {
            specialDays ? (
              <>
                <Grid item xs={12} ><span>&nbsp;</span> </Grid>
                <Grid item xs={4} >
                  <FormulaTextField
                    editMode={editMode}
                    label={"Jours spéciaux"}
                    formulaKey={`formulas.specialFormula`}
                    formulaValue={formulaGroup.formulas.specialFormula as string}
                    handleChange={handleChange}
                  />
                </Grid>
                <Grid item xs={8} ><span>&nbsp;</span> </Grid>
              </>
            )
              : (<></>)
          }
          {
            fixedHCperiods ? (
              <>
                <Grid container direction="row" spacing={0}>
                  <PeriodEditor
                    editMode={editMode}
                    periods={formulaGroup.HCperiods && formulaGroup.HCperiods.length > 0 ? formulaGroup.HCperiods : [[new Date("01/01/2000 00:00"), new Date("01/01/2000 23:59")]] as Date[][]}
                    setPeriods={(p) => {
                      handleChange("HCperiods", p)
                    }}
                  />
                </Grid>
              </>
            )
              : (<></>)
          }

        </CardContent>
      </Card>
    </>)
}

function DetailPanel(grid: PriceGrid, onChange: () => void) {
  const classes = useStyles()
  const [newPeriodModalOpen, setNewPeriodModalOpen] = useState(false);
  var [dualTarif, setdualTarif] = useState(grid.isDualTarif);
  var [specialDays, setspecialDays] = useState(grid.hasSpecialDays);
  var [fixedHCperiods, setfixedHCperiods] = useState(grid.fixedHCperiods);
  var [statedata, setstatedata] = useState(grid);
  const auth = useContext(AuthContext)
  const services = new Services(auth.sessionInfo?.accessToken || "")
  return (
    <>
      <Toaster />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Box width="100%" display={'block'} style={{ maxHeight: '50vh' }} >
          <Box mt={1}>
            <Grid container direction="row" spacing={1} justify="center">
              <Grid item xs={1} className={classes.verticalCenter}>
                <Tooltip title="Ajouter une période">
                  <IconButton onClick={() => { setNewPeriodModalOpen(true) }} >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="supprimer">
                  <IconButton disabled={grid.isUsed || false} onClick={async () => { await services.deleteGrid(grid.id); onChange() }} >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={11} className={classes.verticalCenter}>

              </Grid>
              <br />
              <Box width="100%" display={'block'} style={{ maxHeight: '40vh', overflowY: "auto", overflowX: "hidden", backgroundColor: "lightgrey" }} >
                {
                  statedata.formulaGroups.map((object, i) => {
                    if (grid.formulaGroups[i]) {
                      return (<>
                        < FomulaGroupDetail
                          key={grid.id + dualTarif + i + specialDays + fixedHCperiods}
                          grid={grid}
                          specialDays={specialDays}
                          fixedHCperiods={fixedHCperiods}
                          dualTarif={dualTarif}
                          index={i}
                          onSubmit={async (g) => {
                            grid.formulaGroups[i] = g
                            try {
                              grid.isDualTarif = dualTarif
                              await services.updateGrid(grid)
                              onChange()
                              toast.success('Grille sauvegardée')
                            } catch (e) {
                              toast.error('Erreur: changements non sauvegardés')
                            }
                          }}
                        />
                        <br />
                      </>
                      )
                    }
                  })
                }
              </Box>
            </Grid>
          </Box>
        </Box>
      </MuiPickersUtilsProvider >
      <NewPeriodModal
        open={newPeriodModalOpen}
        onClose={() => setNewPeriodModalOpen(false)}
        onSubmit={async (start, end) => {
          try {
            grid.formulaGroups.push({
              start,
              end,
              TVA: 20,
              allDaysFormula: true,
              HCperiods: [],
              formulas: {
                "fri": { "formula": "" },
                "mon": { "formula": "" },
                "sat": { "formula": "" },
                "sun": { "formula": "" },
                "thu": { "formula": "" },
                "tue": { "formula": "" },
                "wed": { "formula": "" },
                "specialFormula": "",
                HCPeriods: grid.fixedHCperiods ? [[new Date("01/01/2000 00:00"), new Date("01/01/2000 23:59")]] : null
              }
            })
            grid.isDualTarif = dualTarif

            await services.updateGrid(grid)
            onChange()
            toast.success('Grille savegardée')
          } catch (e) {
            toast.error('Erreur: changements non sauvegardés')
          }
        }}
      />
    </>
  )
}
