import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { Company, Device, DeviceTypeLabels, FirmWare, User } from '../libs/types'
import MaterialReactTable from 'material-react-table';
import type { MRT_ColumnDef, MaterialReactTableProps } from 'material-react-table';
import { Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, TextField } from '@material-ui/core'
import { Stack } from '@mui/material'
import { CompaniesContext } from '../contexts/companyContext'
import { t } from 'i18next'
import {
  Print as PrintIcon
} from '@mui/icons-material';
import { useReactToPrint } from 'react-to-print';
import IconButton from '@material-ui/core/IconButton'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../contexts/authContext'
import DeviceLabel from '../components/document/deviceLabel'
import bgF2m from '../ressources/backgroundf2m.png';
import FirmwareList from '../components/firmwares/firmwares'
import { CreateFwModal } from '../components/firmwares/createfwModal'
import { getallFw } from '../service/firmwares'
import { UsersContext } from '../contexts/userContext'
import Services from '../service/services'
import { CreateModal, DeviceRepresentation } from '../components/devices/createModel'


var data: {
  firmwares: Map<string, FirmWare>,
  devices: DeviceRepresentation[]
}
  ;

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    height: 'calc(100vh - 7vh)',
    backgroundImage: `url(${bgF2m})`,
    backgroundSize: 'cover',
    backgroundColor: "#1e0046"
  },
  title: {
    textAlign: 'center',
  },
  spaced: {
    marginLeft: '15px',
    marginRight: '15px',

  },
  session: {
    width: '80vw',
    overflow: 'auto',
    overflowWrap: 'break-word',
    fontSize: '16px',
  },
  hero: {
    width: '100%',
    background: 'rgb(220,220,220)',
  },
  spacedTop: {
    marginTop: "30px"
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vvw',
    backgroundColor: 'white',
    border: '2px solid #000',
  }
}))


export default function DevicesPage() {
  const classes = useStyles()
  const { t, i18n } = useTranslation();
  const auth = useContext(AuthContext)
  const services = new Services(auth.sessionInfo?.accessToken || "")

  const companyMap = useContext(CompaniesContext).companyMap
  const userMap = useContext(UsersContext).getMap()
  const [createDeviceModalOpen, setCreateDeviceModalOpen] = useState(false);
  const [createFwModalOpen, setCreateFwModalOpen] = useState(false);
  const [fwModalKey, setfwModalKey] = useState(Math.random());
  const [fwKey, setFwKey] = useState(Math.random());
  const [selectedDevice, setSelectedDevice] = useState<Device | null>(null);
  var [statedata, setstatedata] = useState(data);
  const deviceColumns = useMemo<MRT_ColumnDef<DeviceRepresentation>[]>(() => columnFactory(t, !auth.sessionInfo?.isAdmin, statedata ? statedata.firmwares : new Map<string, FirmWare>(), userMap), [i18n.language, statedata])
  const labelRef = useRef<any | null>(null);
  const printLabel =
    useReactToPrint({
      content: () => labelRef!!.current
    })

  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});

  useEffect(() => {
    getall();
  }, []);



  async function getall() {
    var promises = []
    switch (true) {
      case typeof auth.sessionInfo?.fleetManagerProfile !== 'undefined':
        promises.push(services.getCompanyDevices(auth.sessionInfo?.companyID!))
        break;
      case auth.sessionInfo?.isAdmin:
        promises.push(services.getAllDevices())
        break;
      default://endUser
        //unauthaurized
        break;
    }
    const fws = await getallFw()
    const firmwares = new Map<string, FirmWare>()
    fws.forEach(fw =>
      firmwares.set(fw.id, fw)
    )
    let devicesResults = (await Promise.all(promises)).flat();
    var devices: DeviceRepresentation[] = []
    devicesResults.forEach(device => {
      devices.push({
        company: companyMap.get(device.companyID) || null,
        device
      })
    })
    setstatedata({
      firmwares,
      devices
    })
  }

  const handleSaveRowEdits: MaterialReactTableProps<DeviceRepresentation>['onEditingRowSave'] =
    async ({ exitEditingMode, row, values }) => {
      /*if (!Object.keys(validationErrors).length) {
        console.log(values.name)
        values.id = companyMap.get(row.original.company.name)!.id
        await updateCompany(values);
        exitEditingMode(); //required to exit editing mode and close modal
        companies.refresh()
      }*/
    };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };


  return (
    <Grid className={classes.root} container direction="column" alignItems="center">
      <Grid container spacing={1} >
        {auth.sessionInfo?.isAdmin ? (
          <Grid container item xs={3} direction="column" >
            <Box maxWidth={'100%'} className={[classes.spaced, classes.spacedTop].join(" ")} >
              <FirmwareList
                key={fwKey}
                onCreateClick={() => { setfwModalKey(Math.random()); setCreateFwModalOpen(true) }}
              />
              <br />
            </Box>
          </Grid>

        ) : (<></>)}

        <Grid container item xs={9} direction="column" >
          <Box maxWidth={'100%'} className={[classes.spaced, classes.spacedTop].join(" ")} >
            <MaterialReactTable
              enableEditing={auth.sessionInfo?.isAdmin}
              initialState={{ density: "compact" }}
              onEditingRowSave={handleSaveRowEdits}
              onEditingRowCancel={handleCancelRowEdits}
              enableRowActions={auth.sessionInfo?.isAdmin}
              columns={deviceColumns}
              data={statedata ? statedata.devices : []}
              enableColumnOrdering={false}
              enableHiding={false}
              enableDensityToggle={false}
              enableGlobalFilter={false} //turn off a feature
              renderTopToolbarCustomActions={() => {
                if (!auth.sessionInfo?.isAdmin) {
                  return null
                }
                return (
                  <Button
                    color="secondary"
                    onClick={() => setCreateDeviceModalOpen(true)}
                    variant="contained"
                  >
                    +
                  </Button>)
              }

              }
              renderRowActions={({ row }) => (
                <Box >
                  <IconButton onClick={() => {
                    setSelectedDevice(row.original.device);
                    printLabel()
                  }}>
                    <PrintIcon />
                  </IconButton>
                </Box>
              )}
            />
            <br />
          </Box>
        </Grid>
        <DeviceLabel
          device={selectedDevice}
          ref={labelRef}

        />
      </Grid>
      <CreateModal
        columns={deviceColumns}
        open={createDeviceModalOpen}
        onClose={() => setCreateDeviceModalOpen(false)}
        onSubmit={async (d) => { await services.createDevice(d); await getall() }}
        title={t("addDevice")}
        confirmButtonCaption={t("companyCreateConfirm")}
        firmwares={statedata ? Array.from(statedata.firmwares.values()) : []}
      />
      <CreateFwModal
        key={fwModalKey}
        open={createFwModalOpen}
        onClose={() => setCreateFwModalOpen(false)}
        onSubmit={() => { setCreateFwModalOpen(false); setFwKey(Math.random()) }}
      />
    </Grid>
  )
}


const columnFactory = (t: (s: string) => string, isFleetManager: boolean = false, firmwares: Map<string, FirmWare>, users: Map<string, User>): MRT_ColumnDef<DeviceRepresentation>[] => {
  var columns: MRT_ColumnDef<DeviceRepresentation>[] = []

  if (!isFleetManager) {
    columns.push({
      accessorKey: 'company.name',
      header: t('client')
    })
  }

  columns.push(
    {
      accessorKey: 'device.user',
      header: t('user'),
      accessorFn: (row) => {
        return row.device.user ? users.get(row.device.user)?.firstName + " " + users.get(row.device.user)?.lastName : " - "
      },
      enableEditing: false
    },
    {
      accessorKey: 'device.id',
      header: "ID",
      enableEditing: false
    },
    {
      accessorKey: 'device.type',
      header: t('model'),
      Cell: cell => (
        <Box component="span" >
          {t(cell.row.original.device.type)}
        </Box>
      )
    })
  if (!isFleetManager) {
    columns.push(
      {
        accessorKey: 'device.firmwareID',
        header: t('firmware'),
        Cell: cell => (
          <Box component="span" >
            {firmwares.get(cell.row.original.device.firmwareID)?.name || " - "}
          </Box>
        )

      },
      {
        accessorKey: 'device.lastSeen',
        header: t('lastSeen'),
      },
      {
        accessorKey: 'device.threshold',
        header: t('deviceThreshold'),
      },
    )
  }
  return columns

}

