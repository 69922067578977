

import { Session } from "../../libs/types";
import { useEffect, useState } from "react";
import { getIndexes } from "../../service/Indexes";
import { GetGMap } from "../gmaps";
import { useTranslation } from "react-i18next";
import { ChartValue, LineChart } from "../graph";
import { tsToDate } from "../../libs/utils";

interface SessionPanelProps {
    session: Session;
    start: Date;
    end: Date;
}

export default function SessionPanel({ session, start, end }: SessionPanelProps) {
    const chartValues: ChartValue[] = [];
    const [statedata, setstatedata] = useState([] as ChartValue[]);
    const { t, i18n } = useTranslation();

    const refresh = async () => {
        
        if (!statedata || statedata.length === 0) {
            if (session.deviceID) {
                const idxs = await getIndexes(session.deviceID, start, end);
                const newChartValues = idxs.map((idx) => ({
                    ts: idx.ts,
                    value: idx.index,
                }));
                setstatedata(newChartValues);
            }
        }
    };

    /*useEffect(() => {
        refresh();
    }, [session]);*/
    refresh();
    if (!session.carDetails.SOC || !session.carDetails.SOC.start) {
        return <>{t('carTelemetryNotavailaibe')}</>;
    }

    return (
        <>
            <div style={{ fontStyle: 'normal', float: 'left', height: '500px', width: '600px' }}>
                <div style={{ float: 'left', width: '300px' }}>
                    <b>{t('vehiculeData')} :</b>
                    <br />
                    <b>{t('start')}:</b>
                    <br />
                    <b>{t('startCapacity')}</b>
                    <br />
                    <b>{t('end')}:</b>
                    <br />
                    <b>{t('endCapacity')}:</b>
                    <br />
                    <b>{t('chargeClass')}</b>
                    <br />
                </div>

                <div style={{ float: 'right', width: '300px' }}>
                    &nbsp;
                    <br />
                    {tsToDate(session.carDetails.SOC.start).toLocaleString()}
                    <br />
                    {session.carDetails.SOC.gaugeStart} %
                    <br />
                    {tsToDate(session.carDetails.SOC.end).toLocaleString()}
                    <br />
                    {session.carDetails.SOC.gaugeEnd} %
                    <br />
                    {session.chargeClass} kw
                </div>
                <br />
                <br />
                <br />

                <div style={{ float: 'left', height: '200px', width: '500px' }}>
                    <br />
                    <b>{t('carLocation')}</b>
                    <br />
                    <br />
                    {GetGMap({ location: session.carDetails.location, text: 'Vehicule' }, 500, 300)}
                </div>
            </div>

            <div style={{ float: 'right' }}>
                <b>Coube de charge vehicule :</b>
                <LineChart key={statedata ? statedata.length : 0} values={statedata} width={700} height={450} />
            </div>
        </>
    );
}