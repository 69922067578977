import { Address, Car, ChargePoint, Company, Grid, Session, User, Tag, TagMember, CreditNote, Device, CompanyStats } from '../libs/types';
import { getCompanyUsers, createUser, disableUser, enableUser, getAllUsers, invite, updateUser } from './users';
import { list as listGrids, getGrid, createGrid, updateGrid, computeMockSession, deleteGrid } from './grid';
import { archiveSessions, getSessionForCompany, getSessionForUser, unArchiveSessions, updateSesssionsCars } from './sessions';
import { getAllChargePointsforCompany, getAllChargePointsforUser, createChargePoint, updateChargePoint, setNonDocGrid, getAllChargePoints } from './chargepoints';
import { getAllCompanies, getCompany, createCompany, updateCompany } from './companies';
import { createCar, getAllCarsCompany, updateCar } from './cars';
import { attachTag, createTag, deleteTag, detachTag, getAllTagsForCompany, listTagMembers } from './tags';
import { generateNote, getNoteForCompany, getNoteForUser } from './creditNote';
import { createDevice, getCompanyDevices, getAllDevices } from './devices';
import { getCompanyStats } from './stats';

class Services {
    private authToken: string;

    constructor(authToken: string) {
        this.authToken = authToken;
    }
    // Example service function
    public getUsers(): Promise<User[]> {
        return getAllUsers(this.authToken);
    }
    public updateUser(user: User): Promise<void> {
        return updateUser(this.authToken, user);
    }
    public createUser(user: User): Promise<void> {
        return createUser(this.authToken, user);
    }
    public getCompanyUsers(companyId: string): Promise<User[]> {
        return getCompanyUsers(this.authToken, companyId);
    }
    public listGrids(): Promise<Grid[]> {
        return listGrids(this.authToken);
    }
    //same from grid.ts
    public getGrid(id: string): Promise<Grid | null> {
        return getGrid(this.authToken, id);
    }
    public createGrid(grid: Grid): Promise<void> {
        return createGrid(this.authToken, grid);
    }
    public updateGrid(grid: Grid): Promise<void> {
        return updateGrid(this.authToken, grid);
    }

    public setNonDocGrid(cp: ChargePoint,
        gridID: string,
        periods: Date[][],
        HCF: number | null,
        HPF: number | null
    ): Promise<void> {
        return setNonDocGrid(this.authToken, cp, gridID, periods, HCF, HPF);
    }


    public deleteGrid(id: string): Promise<void> {
        return deleteGrid(this.authToken, id);
    }
    public computeMockSession(params: any): Promise<void> {
        return computeMockSession(this.authToken, params);
    }
    //same from sessions.ts 
    public getSessionForUser(user: User, start: Date | null, end: Date | null,archivedOnly:boolean): Promise<Session[]> {
        return getSessionForUser(this.authToken, user, start, end,archivedOnly);
    }
    public  getSessionForCompany (companyID: string, start: Date | null, end: Date | null,archivedOnly:boolean): Promise<Session[]> {
        return getSessionForCompany(this.authToken, companyID, start, end,archivedOnly);
    }
    public updateSesssionsCars(user: User, sessions: Session[]): Promise<void> {
        return updateSesssionsCars(this.authToken, user, sessions);
    }

    public archiveSessions(user: User, sessions: Session[]): Promise<void> {
        return archiveSessions(this.authToken, user, sessions);
    }
    public unArchiveSessions(user: User, sessions: Session[]): Promise<void> {
        return unArchiveSessions(this.authToken, user, sessions);
    }
    //same from chargepoints.ts
    public getAllChargePoints(): Promise<ChargePoint[]> {
        return getAllChargePoints(this.authToken);
    }

    public getAllChargePointsforCompany(companyID: string): Promise<ChargePoint[]> {
        return getAllChargePointsforCompany(this.authToken, companyID);
    }
    public getAllChargePointsforUser(user: User): Promise<ChargePoint[]> {
        return getAllChargePointsforUser(this.authToken, user);
    }

    public createChargePoint(address: Address, companyId: String, name: String): Promise<String> {
        return createChargePoint(this.authToken, address, companyId, name);
    }
    public updateChargePoint(cp: ChargePoint): Promise<void> {
        return updateChargePoint(this.authToken, cp);
    }
    //same from companies.ts
    public getAllCompanies(): Promise<Company[]> {
        return getAllCompanies(this.authToken);
    }
    public getCompany(id: string): Promise<Company | null> {
        return getCompany(this.authToken, id);
    }
    public createCompany(company: Company): Promise<void> {
        return createCompany(this.authToken, company);
    }
    public updateCompany(company: Company): Promise<void> {
        return updateCompany(this.authToken, company);
    }
    public createCar(car: Car): Promise<void> {
        return createCar(this.authToken, car);
    }
    public updateCar(car: Car): Promise<void> {
        return updateCar(this.authToken, car);
    }
    public getAllCarsCompany(companyID: string): Promise<Car[]> {
        return getAllCarsCompany(this.authToken, companyID);
    }
    public createTag(type: string, companyId: String, name: String, label: String): Promise<void> {
        return createTag(this.authToken, type, companyId, name, label);
    }
    public deleteTag(companyId: String, name: String): Promise<void> {
        return deleteTag(this.authToken, companyId, name,);
    }
    public listTagMembers(companyId: string, name: string,): Promise<TagMember[]> {
        return listTagMembers(this.authToken, companyId, name);
    }
    public listTags(companyId: string): Promise<Tag[]> {
        return getAllTagsForCompany(this.authToken, companyId);
    }
    public attachTag(companyId: string, type: string, tagName: string, entityID: string): Promise<void> {
        return attachTag(this.authToken, companyId, type, tagName, entityID);
    }
    public detachTag(companyId: string, tagName: string, entityID: string): Promise<void> {
        return detachTag(this.authToken, companyId, tagName, entityID);
    }
    public getNoteForCompany(companyID: string): Promise<CreditNote[]> {
        return getNoteForCompany(this.authToken, companyID);
    }
    public getNoteForUser(user: User): Promise<CreditNote[] | null> {
        return getNoteForUser(this.authToken, user);
    }
    public generateNote(user: User, sessionIds: string[], start: Date, end: Date | null): Promise<void> {
        return generateNote(this.authToken, user, sessionIds, start, end);
    }

    public getCompanyDevices(companyID: string): Promise<Device[]> {
        return getCompanyDevices(this.authToken, companyID);
    }
    public getAllDevices(): Promise<Device[]> {
        return getAllDevices(this.authToken);
    }
    public createDevice(device: Device): Promise<void> {
        return createDevice(this.authToken, device);
    }
    getCompanyStats(company: Company, start: Date | null, end: Date | null): Promise<CompanyStats> {
      return getCompanyStats(this.authToken, company, start, end);
    }











    // ... Add more service functions as needed
}

export default Services;