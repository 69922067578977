import { DialogTitle, IconButton, Dialog, MenuItem, DialogContent, Typography, Select, Button, DialogActions, makeStyles } from "@material-ui/core";
import { Cancel, Add, Remove } from "@material-ui/icons";
import { FileUpload } from "@mui/icons-material";
import { createRef, useContext, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { GridContext } from '../../contexts/GridContext';
import { ChargePoint, Company } from "../../libs/types";
import { uploadToS3 } from "../../service/chargepoints";
import { SingleInputTimeRangeField } from '@mui/x-date-pickers-pro/SingleInputTimeRangeField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import clone from 'nanoclone'
import SpecialDaysSelector from "../grid/specialDayselector";
import FixedPriceSetter from "../grid/fixedPricedSetter";
import Services from "../../service/services";
import { AuthContext } from "../../contexts/authContext";

interface DocumentModalProps {
    cps: ChargePoint[],
    company: Company,
    onClose: () => void;
    onSubmit: (values: ChargePoint[]) => void;
    onSucess: (validateRequired: boolean) => void;
    open: boolean;
}

const useStyles = makeStyles((theme) => ({
    spaced: {
        marginLeft: "15px",
        marginRight: "15px"
    },
    popupTitle: {
        color: theme.palette.primary.dark,
        fontWeight: "bold",
        fontSize: "1.8rem!important"
    },

}))

export const DocumentPopup = ({
    open,
    company,
    cps,
    onClose,
    onSucess,
    onSubmit,

}: DocumentModalProps) => {
    const { t } = useTranslation();
    const grids = useContext(GridContext).grids
    //depending of the companySetting restaring display to private grids
    const auth = useContext(AuthContext)
    const services = new Services(auth.sessionInfo?.accessToken || "")
    const classes = useStyles()
    var [gridID, setGridID] = useState(grids[0] ? grids[0].id : '');
    var [isLoading, setIsLoading] = useState(false);
    var [periodErrorMessage, setperiodErrorMessage] = useState("");
    const [selectedSpecialDays, setselectedSpecialDays] = useState([] as string[]);
    var [periods, setPeriods] = useState([[new Date("01/01/2000 00:00"), new Date("01/01/2000 23:59")]] as [Date, Date][]);
    if (grids[0] && grids.length == 0) {
        gridID = grids[0].id
    }
    var [isDualTarif, setisDualTarif] = useState(grids[0] ? grids[0].isDualTarif : false);
    var [isFixedPrice, setisFixedPrice] = useState(grids[0] ? grids[0].isFixedPrice : false);
    var [isSpecialDays, setspecialDays] = useState(grids[0] ? grids[0].hasSpecialDays : false);
    var [billNeeded, setBillNeeded] = useState(grids[0] ? grids[0].billNeeded : true);
    const [fixedPrices, setfixedPrices] = useState({ HPF: '0', HCF: '0' } as { HPF: string, HCF: string });
    if (!open) {
        return (<></>)
    }

    var updateCP = cps.filter((cp) => {
        return !cp.grid
    })
    const addPeriod = () => {
        let p = clone(periods);
        p.push([new Date("01/01/2000 00:00"), new Date("01/01/2000 23:59")]);
        setPeriods(p);
        validatePeriods(p);
    };

    const removePeriod = (index: number) => {
        let p = clone(periods);
        p.splice(index, 1);
        setPeriods(p);
        validatePeriods(p);
    };
    const setNonDocGrid = (cp: ChargePoint) => {
        services.setNonDocGrid(cp, gridID,
            periods,
            fixedPrices.HCF ? Number(fixedPrices.HCF) : null,
            fixedPrices.HPF ? Number(fixedPrices.HPF) : null,
        ).then((res) => {
            onSucess(false)
        }).catch((e) => {
            console.log(e)
        })
    }
    var validatePeriods = (periods: [Date, Date][]) => {
        var isError = false
        periods.forEach((period, index) => {
            if (period[0] == null || period[1] == null) {
                setperiodErrorMessage(t("periodsError"))
                isError = true
            }
            if (isNaN(period[0].getTime()) || isNaN(period[1].getTime())) {
                setperiodErrorMessage(t("periodsError"))
                isError = true
            }

        })
        if (periods.length == 1) {
            if (!isError) {
                setperiodErrorMessage("")
            }
        }
        //verifie si 2 periode ne s'overlappent pas
        for (let i = 0; i < periods.length; i++) {
            for (let j = i + 1; j < periods.length; j++) {
                if (periods[i][0] && periods[j][0] && periods[i][0] >= periods[j][0] && periods[i][0] <= periods[j][1]) {
                    setperiodErrorMessage(t("periodsOverlapError"))
                    isError = true
                }
                if (periods[i][1] && periods[j][0] && periods[i][1] >= periods[j][0] && periods[i][1] <= periods[j][1]) {
                    setperiodErrorMessage(t("periodsOverlapError"))
                    isError = true
                }
                if (periods[j][0] && periods[i][0] && periods[j][0] >= periods[i][0] && periods[j][0] <= periods[i][1]) {
                    setperiodErrorMessage(t("periodsOverlapError"))
                    isError = true
                }
                if (periods[j][1] && periods[i][0] && periods[j][1] >= periods[i][0] && periods[j][1] <= periods[i][1]) {
                    setperiodErrorMessage(t("periodsOverlapError"))
                    isError = true
                }
            }
        }

        if (!isError) {
            setperiodErrorMessage("")
        }


    }
    return (

        <Dialog open={open} maxWidth={"md"}>
            <DialogTitle ><span className={classes.popupTitle}>{t("requiredDocument")}</span></DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Typography>
                        {t("selectGridExplanation")}
                    </Typography>
                    <br />


                    {
                        updateCP.map(cp => {

                            return (
                                <>
                                    <Typography>
                                        {cp.name}
                                    </Typography>
                                    <Select
                                        style={{ minWidth: "150px" }}
                                        labelId="Point de Charge"
                                        id="demo-simple-select"
                                        value={gridID}
                                        label="Point de Charge"
                                        onChange={(e) => {
                                            setGridID(String(e.target.value))
                                            var isDual = false
                                            var isFixed = false
                                            var specialdays = false
                                            for (const g of grids) {
                                                if (g.id == e.target.value) {
                                                    isDual = g.isDualTarif || false
                                                    isFixed = g.isFixedPrice || false
                                                    specialdays = g.hasSpecialDays || false
                                                    billNeeded = typeof g.billNeeded !== "undefined" ? g.billNeeded : true

                                                }
                                            }
                                            setPeriods([[new Date("01/01/2000 00:00"), new Date("01/01/2000 23:59")]])
                                            setisDualTarif(isDual)
                                            setisFixedPrice(isFixed)
                                            setspecialDays(specialdays)
                                            setBillNeeded(billNeeded)
                                        }
                                        }
                                    >
                                        {grids.map(grid => {
                                            return (<MenuItem value={grid.id}>{grid.provider + " - " + grid.name}</MenuItem>)
                                        })}

                                    </Select>
                                    {
                                        isSpecialDays ?
                                            <>
                                                <br />
                                                <br />
                                                <Typography>
                                                    {t("specialDaysExplanation")}
                                                </Typography>
                                                <br />
                                                <SpecialDaysSelector
                                                    handleChange={(days: string[]) => setselectedSpecialDays(days)}
                                                />
                                            </>
                                            :
                                            <></>
                                    }
                                    {
                                        isFixedPrice ?
                                            <>
                                                <br />
                                                <br />
                                                <Typography>
                                                    {t("fixedPriceExplanation")}
                                                </Typography>
                                                <br />
                                                <FixedPriceSetter
                                                    isDualTarif={isDualTarif || false}
                                                    handleChange={(tarif) => setfixedPrices(tarif)}

                                                />
                                            </>
                                            :
                                            <></>
                                    }
                                    {
                                        isDualTarif ?
                                            <>
                                                <br />
                                                <br />
                                                <Typography>
                                                    {t("dualTarifExplanation")}
                                                </Typography>
                                                <br />

                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    {periods.map((period, index) => (
                                                        <>
                                                            <SingleInputTimeRangeField key={index} className={classes.spaced}

                                                                label={t("offPeakPeriod") + " " + (index + 1)}
                                                                value={period}
                                                                ampm={false}
                                                                color={"error"}
                                                                onChange={(newValue) => {
                                                                    let p = clone(periods);
                                                                    p[index] = [newValue[0]!, newValue[1]!];
                                                                    setPeriods(p)
                                                                    validatePeriods(p)
                                                                }}
                                                            />
                                                            {index == 0 ?
                                                                <IconButton onClick={addPeriod}>
                                                                    <Add color={"action"} />
                                                                </IconButton>
                                                                : <>
                                                                    <IconButton onClick={() => { removePeriod(index) }}>
                                                                        <Remove />
                                                                    </IconButton> </>

                                                            }
                                                            <br />
                                                            <br />
                                                        </>
                                                    ))}


                                                </LocalizationProvider>
                                                <Typography color={"error"}>
                                                    {periodErrorMessage}
                                                </Typography>
                                                <br />
                                            </>
                                            :
                                            <></>
                                    }
                                    <input
                                        style={{ display: 'none' }}
                                        id="raised-button-file"
                                        multiple
                                        type="file"
                                        onChange={async (e) => {
                                            if (e.target.files) {
                                                try {
                                                    setIsLoading(true)
                                                    await uploadToS3({
                                                        file: e.target.files[0],
                                                        cp,
                                                        gridID,
                                                        periods,
                                                        HCF: fixedPrices.HCF ? Number(fixedPrices.HCF) : null,
                                                        HPF: fixedPrices.HPF ? Number(fixedPrices.HPF) : null
                                                    })
                                                    setIsLoading(false)
                                                    onSucess(true)
                                                }
                                                catch (e) {
                                                    toast.error("error uploading document")
                                                    console.log(e)
                                                }
                                            }
                                            return null
                                        }}
                                    />
                                    {
                                        billNeeded ? (
                                            <>
                                                <label htmlFor="raised-button-file">
                                                    <Button disabled={periodErrorMessage || !gridID ? true : false} variant="contained" color="primary" style={{ display: isLoading ? "none" : "inline-flex" }} className={classes.spaced} startIcon={<FileUpload />} component="span">
                                                        {t("billUploadButton")}
                                                    </Button>
                                                </label>
                                                <span className="loader" style={{ width: '50px', display: isLoading ? "block" : "none" }}></span>
                                                <br />
                                                <br />
                                                <Typography>
                                                    {t("billRequiredExplanation")}
                                                </Typography>

                                            </>
                                        ) :
                                            <>
                                                <label htmlFor="raised-button-file">
                                                    <Button variant="contained" disabled={!gridID} color="primary" className={classes.spaced} style={{ display: "inline-flex" }} onClick={() => setNonDocGrid(cp)}> {t("ok")}</Button>
                                                </label>
                                            </>
                                    }

                                </>
                            )
                        })
                    }
                </form>
            </DialogContent>
            <DialogActions /*sx={{ p: '1.25rem' }}*/>
                <Button variant="contained" startIcon={<Cancel />} onClick={onClose}> {t("cancel")}</Button>
            </DialogActions>
        </Dialog>
    );
};


