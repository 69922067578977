
import Chart from "react-apexcharts";




export interface ChartValue {
  ts: number,
  value: number
}

const getSeries = (values: ChartValue[]): ({ ts: number, value: number }[]) => {
  let res: { ts: number, value: number }[] = []
  values.forEach(value => {
    let d = new Date(Number(value.ts) * 1000)
    res.push(
      {
        ts: value.ts*1000,//`${d.getHours()} : ${d.getMinutes()}`,
        value: value.value.valueOf()
      }
    )
  }
  )
  return res
}


export function LineChart({ values, width, height, annotations = [] }: {
  values: ChartValue[],
  width: number,
  height: number,
  annotations?: any[]
}) {
  let xano : XAxisAnnotations[]| undefined
  if (annotations.length > 0) {
    
    xano = annotations.map(annotation => {
      return {
        x:  annotation.start,//`${new Date(annotation.start*1000).getHours()} : ${new Date(annotation.start*1000).getMinutes()}` , //${d.getHours()} : ${d.getMinutes()}
        x2:  annotation.end,//`${new Date(annotation.end*1000).getHours()} : ${new Date(annotation.end*1000).getMinutes()}`,
        fillColor: annotation.label == "HP" ? 'red' :'#B3F7CA',
        opacity: 0.4,
        label: {
          borderColor: '#B3F7CA',
          style: {
            fontSize: '10px',
            color: '#fff',
            background: '#00E396',
          },
          //offsetY: -10,
          text: annotation.label,
        }
      }
    })


  }
  let serie = getSeries(values)
  let cat = serie.map(s => s.ts)
  let val = serie.map(s => s.value)
  return (
    <Chart
      options={
        {
          annotations: {
            xaxis: xano
          },
          stroke: {
            curve: 'smooth',
          },

          chart: {
            animations: {
              enabled: true
            },
            id: "Indexes"
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: cat,
            type: 'datetime',
              labels: {
                datetimeFormatter: {
                  year: 'yyyy',
                  month: 'MMM \'yy',
                  day: 'dd MMM',
                  hour: 'HH:mm'
                }
              }
          },
          yaxis: {
            min: Math.min(...val),
            max: Math.max(...val),
          }
        }}
      series={[
        {
          name: "HP",
          data: val
        }
      ]}

      type="line"
      width={width + "px"}
      height={height + "px"}
    />)
}