

export interface User {
    userName: string;
    companyID: string | null;
    profile: "endUser" | "fleetManager" | "admin";
    email: string;
    firstName: string;
    lastName: string;
    matricule: string;
    lastcreditNote: Date | null;
    status: string
    enabled: Boolean
    chargePoints: string[]
    language: string
    restrictions: Tag[] | null
    tags: Tag[] | null
};

export interface Session {
    companyID: string | null
    chargePointID: string
    deviceID: string | null
    id: string
    status: "Started" | "Ended"
    start: number
    end: number
    duration: number
    chargeClass: number
    consumedPower: {
        startIndexes: Index[]
        endIndexes: Index[]
        total: number
    }
    billing: {
        grid: any
        total: number
        HT: number
        TVA: number
    }
    carDetails: {
        VIN: string
        PlateNumber: string
        energyIn: number
        SOC: {
            gaugeEnd: number
            gaugeStart: number
            start: number
            end: number
            estimatedEnergyDelta: number
        }
        location: {
            lng: number;
            lat: number;
        }
    }
    creditNote: {
        id: string
        ts: number
    }
};

export interface Company {
    id: string;
    name: string;
    adress: string;
    zipCode: string;
    city: string;
    logoUrl: string | null;
    notificationEmail: string;
    notificationLanguage: "en" | "fr";
    options: {
        companyOnlyGrid: boolean
        nonCompanyCarEnable: boolean
    }
    isTechnical: boolean

};

export interface SohPoint {
    ts: number,
    soh: number,
    class: 2 | 3 | 7 | 11 | 22 | null
    params: {
        deltaSOC: number,
        energyIn: number,
        SOCEnd: number
        SOCstart: number
    }
}

export interface Car {
    VIN: string;
    brand: string;
    model: string;
    plastikID?: string;
    group?: string;
    isManual?: boolean;
    plateNumber: string;
    companyID: string;
    status : "active" | "inactive"
    lastCharge: Date | null;
    user: string | null;
    tags: Tag[] | null;

};


export interface CarCharge {
    VIN: string,
    ts: number,
    end: number,
    startGauge: number,
    endGauge: number,
    energyAdded: number,
    location: {
        lng: number,
        lat: number
    },
    chargePointID: string | null
};

export interface UserStats {
    firstName: string,
    lastName: string,
    groups: string[],
    sessionNb: number,
    total: number,
    totalPower: number,
    creditNoteNb: number
    userName: string | null,
    tags: Tag[]

};

export interface ChargePointStats {
    sessionNb: number,
    total: number,
    totalPower: number,
    creditNoteNb: number
    cpId: string | null

};

export interface CompanyStats {
    companyID: string,
    sessionNb: number,
    total: number,
    totalPower: number,
    creditNoteNb: number
    userStats: UserStats[]

};

export interface Index {
    ts: number;
    index: number
    power: number
    deviceID: String;

};

export interface ChargePoint {
    companyID: string;
    lastIndex: number,
    isActive: boolean;
    id: string;
    name: string;
    position: string;
    isCharging: boolean;
    grid: string;
    address: Address | null;
    type: 'personal' | 'company' | 'roaming';
    isGridValidated: boolean
    users: string[]
};

export const DeviceTypeLabels = ["T2TriBLE", "T2MonoBLE", "MonoBLE", "T2Tri4G", "T2Mono4G", "Mono4G"]

export interface Device {
    companyID: string;
    isActive: boolean;
    id: string;
    lastSeen: number;
    firmwareID: string;
    type: "T2TriBLE" | "T2MonoBLE" | "MonoBLE" | "T2Tri4G" | "T2Mono4G" | "Mono4G";
    threshold: number
    user: string | null
};


export interface FirmWare {
    file: string
    id: string
    name: string
    type: "T2TriBLE" | "T2MonoBLE" | "MonoBLE" | "T2Tri4G" | "T2Mono4G" | "Mono4G";
};

export interface Address {
    location: {
        lng: number;
        lat: number;
    }
    text: string
}
export interface Note {
    id: string,
    ts: number,
    start: number,
    end: number,
    sessions: string[],
    billing: {
        grid: any
        total: number
        HT: number
        TVA: number
    }
    file: string
}



export interface CreditNote {
    userName: string,
    companyID: string,
    firstName: string,
    lastName: string,
    matricule: string,
    notes: Note[]
}

export interface FormulaGroup {
    start: Date,
    end: Date,
    TVA: number,
    allDaysFormula: boolean
    HCperiods: Date[][] | null
    formulas: {
        "fri": { "formula": string },
        "mon": { "formula": string },
        "sat": { "formula": string },
        "sun": { "formula": string },
        "thu": { "formula": string },
        "tue": { "formula": string },
        "wed": { "formula": string },
        "specialFormula": string
        HCPeriods: Date[][] | null
    }
}



export interface calendar {
    ID: string,
    data: any
}

export interface Tag {
    companyID: string,
    label: string,
    name: string,
    type: string
    hasMembers: boolean
}


export interface TagMember {
    id: string,
    type: string
}

export interface Grid {
    id: string,
    name: string,
    provider: string,
    isFixedPrice: boolean,
    isDualTarif: boolean,
    hasSpecialDays: boolean,
    fixedHCperiods: boolean
    formulaGroups: FormulaGroup[]
    isUsed: boolean
    companyID: string | null
    billNeeded: boolean

}


export type PresignedPostUrlResponse = {
    url: string;
    fields: {
        key: string;
        acl: string;
        bucket: string;
    };
    filePath: string;
};
