import axios from 'axios'


import { BASE_URL } from './conf'
import { calendar } from '../libs/types'

export async function getTempoCalendar(): Promise<calendar> {
    return new Promise(function (resolve, reject) {
        axios.get(BASE_URL + '/calendar/tempo', {
            responseType: 'json',
        })
            .then((res: any) => {
                if (!res.data || res.data.length == 0) {
                    reject("calendar not found")
                }
                resolve(res.data)
            }).catch(e => reject(e))
    })
    

}