import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Stack } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { useContext, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Grid as PriceGrid } from '../../libs/types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Modal, Select, TextField, Tooltip, makeStyles } from '@material-ui/core'
import { Cancel } from "@mui/icons-material";
import Checkbox from '@mui/material/Checkbox';
import { CompaniesContext } from "../../contexts/companyContext";



const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    width: '99%',
    marginLeft: '10px',
    marginRight: '10px',
  },
  title: {
    textAlign: 'center',
    width: '100%',
    overflow: 'none'
  },
  spaced: {
    marginLeft: "15px"
  },
  popupTitle: {
    color: theme.palette.primary.dark,
    fontWeight: "bold",
    fontSize: "1.8rem!important"
  },
  session: {
    overflowWrap: 'break-word',
    fontSize: '16px',
  },
  verticalCenter: {
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  green: {
    backgroundColor: 'green',
  },
  hero: {
    width: '100%'
  }
}))

interface newGridModalProps {
  columns: MRT_ColumnDef<PriceGrid>[];
  onClose: () => void;
  onSubmit: (values: PriceGrid) => void;
  open: boolean;
  allowPublicGrids: boolean;
}


export const NewGridModal = ({
  open,
  columns,
  onClose,
  onSubmit,
  allowPublicGrids
}: newGridModalProps) => {
  const companies = useContext(CompaniesContext).getAllCompanies();
  const [selectedCompanyID, setselectedCompany] = useState(allowPublicGrids ? "" : companies[0].id)
  const [values, setValues] = useState<any>(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any),
  );
  const handleSubmit = async () => {
    try {
      if (values.companyID == "") {
        delete values.companyID
      }
      await onSubmit(values);
      toast.success("Grille sauvegardée")
      onClose();
    } catch (e) {
      toast.error("Erreur: Grille non créee")
    }
  };
  const classes = useStyles()


  return (

    <Dialog open={open}>
      <DialogTitle ><span className={classes.popupTitle}>Nouvelle grille</span></DialogTitle>
      <Toaster />
      <DialogContent>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack
              sx={{
                width: '100%',
                minWidth: { xs: '300px', sm: '360px', md: '400px' },
                gap: '1.5rem',
              }}
            >
              {columns.map((column) => {
                if (column.accessorKey == "companyID") {
                  return (
                    <><Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedCompanyID}
                      label="Select Company"
                      disabled={!allowPublicGrids}
                      onChange={(e) => {

                        setValues({ ...values, ["companyID"]: e.target.value })
                          ; setselectedCompany(e.target.value as string)
                      }}
                    >
                      <MenuItem value={""}>{" - "}</MenuItem>)
                      {companies.map(company =>
                        <MenuItem value={company.id}>{company.name}</MenuItem>)
                      }
                    </Select></>
                  )
                }
                if (["fixedHCperiods", "hasSpecialDays", "isDualTarif", "isFixedPrice", "billNeeded"].includes(column.accessorKey!)) {
                  return (
                    <>
                      <Grid container spacing={2} direction="row" >
                        <Grid item xs={6} className={classes.verticalCenter}>
                          <label>{column.header}</label>
                        </Grid>
                        <Grid item xs={6} className={classes.verticalCenter}>
                          <Checkbox
                            disabled={selectedCompanyID != ""}
                            name={column.accessorKey}
                            checked={values[column.accessorKey!]}
                            onChange={(e) =>
                              setValues({ ...values, [e.target.name]: e.target.checked })
                            } />
                        </Grid>
                      </Grid>
                    </>

                  )
                }
                return (
                  <TextField
                    label={column.header}
                    name={column.accessorKey}
                    onChange={(e) =>
                      setValues({ ...values, [e.target.name]: e.target.value })
                    }
                  />
                )

              })}
            </Stack>
          </form>
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions /*sx={{ p: '1.25rem' }}*/>

        <Button variant="contained" startIcon={<Cancel />} onClick={onClose}>Fermer</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          Creer
        </Button>
      </DialogActions>
    </Dialog>

  );

};

