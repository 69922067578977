import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Stack } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import {  FormulaGroup, Grid as PriceGrid } from '../../libs/types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Modal, TextField, Tooltip } from '@material-ui/core'
import { Cancel } from "@mui/icons-material";

interface newPeriodModalProps {
  onClose: () => void;
  onSubmit: (start : Date,end:Date) => void;
  open: boolean;
}



const FormulaColumnFactory = (): MRT_ColumnDef<FormulaGroup>[] => [
  {
    accessorKey: 'start',
    header: 'debut',
    minSize: 500

  },
  {
    accessorKey: 'end',
    header: 'fin',
    minSize: 500
  }

]


export const NewPeriodModal = ({
  open,
  onClose,
  onSubmit,
}: newPeriodModalProps) => {

  var columns = FormulaColumnFactory()
  const [values, setValues] = useState<any>(() =>
    columns.reduce((acc, column) => {
      if (column.accessorKey == 'start' || column.accessorKey == 'end') {
        acc[column.accessorKey] = new Date();
      } else {
        acc[column.accessorKey ?? ''] = '';
      }
      return acc;
    }, {} as any),
  );

  const handleSubmit = async () => {
    try {
      await onSubmit(values.start,values.end);
      toast.success("Nouvelle periode crée")
      onClose();
    } catch (e) {
      toast.error("Erreur: Nouvelle periode non ajoutee")
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle /*textAlign="center"*/>Nouvelle Période</DialogTitle>
      <Toaster />
      <DialogContent>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack
              sx={{
                width: '100%',
                minWidth: { xs: '300px', sm: '360px', md: '400px' },
                gap: '1.5rem',
              }}
            >
              {columns.map((column) => {
                if (column.accessorKey == 'start' || column.accessorKey == 'end') {
                  return (

                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="start"
                      label={column.header}
                      value={values[column.accessorKey]}
                      onChange={(date: Date | null) => {
                        if (date != null) {
                          setValues({ ...values, [column.accessorKey!]: date })
                        }
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  )
                }
                return (
                  <TextField
                    label={column.header}
                    name={column.accessorKey}
                    onChange={(e) =>
                      setValues({ ...values, [e.target.name]: e.target.value })
                    }
                  />
                )
              })}
            </Stack>
          </form>
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>

      <Button variant="contained" startIcon={<Cancel />} onClick={onClose}>Fermer</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          Creer
        </Button>
      </DialogActions>
    </Dialog>

  );

};

