import React, { useState, useContext } from 'react'

import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@mui/material/Typography';
import Paper from '@material-ui/core/Paper'
import { useValidUsername } from '../../hooks/useAuthHooks'
import { Username } from '../../components/authComponents'
import { AuthContext } from '../../contexts/authContext'
import { useTranslation } from 'react-i18next'
import frFlag from '../../locales/fr/flag.png';
import enFlag from '../../locales/en/flag.png';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import { theme } from '../../libs/theme'
import bgF2m from '../../ressources/backgroundf2m.png';
import f2mlogo from '../../ressources/f2m_logo.png'

const useStyles = makeStyles({
  root: {
    height: '100vh',
    backgroundImage: `url(${bgF2m})`,
    backgroundSize: 'cover',
    backgroundColor: "#1e0046"

  },
  title: {
    color: theme.palette.primary.main,
    fontSize: '2.0rem!important',
  },
  hover: {
    '&:hover': { cursor: 'pointer' },
  },
  text: {
    textAlign: 'left',
  },
})

export default function RequestCode() {
  const classes = useStyles()
  const { username, setUsername, usernameIsValid } = useValidUsername('')
  const [error, setError] = useState('')
  const [resetSent, setResetSent] = useState(false)
  const isValid = !usernameIsValid || username.length === 0
  const history = useHistory()
  const { t, i18n } = useTranslation();
  const authContext = useContext(AuthContext)

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };
  const sendCodeClicked = async () => {
    try {
      await authContext.sendCode(username)
      setResetSent(true)
    } catch (err: any) {
      console.log(err.message)
      switch (err.code) {
        case 'UserNotConfirmedException':
          history.push('verify')
          break;
        case 'UserNotInitException':
          history.push(`createpassword?userName=${username}`)
          break;
        case 'NotAuthorizedException':
          if (err.message === 'User is disabled.') {
            setError(t("userDisabledError"))
            break;
          }
          setError(t("invalidLogin"))
          break;
        case 'UserNotFoundException':
          setError(t("invalidLogin"))
          break;
        case 'LimitExceededException':
          setError(t("limitExceeded"))
          break;
        case 'NetworkError':
          setError(t("networkError"))
          break;
        case 'UserDisabled':
          setError(t("userDisabledError"))
          break;
        default:
          setError(t("genericError"))

      }
      console.log(err)

    }
  }

  const emailSent = (
    <>
      <Box width="80%" >
        <br />
        <Typography className={classes.text} variant="h6">
          {t('resetCodeSent')}
        </Typography>
      <Typography className={classes.text} variant="h6">
        {t('checkEmail')}
      </Typography>
      <br />
    </Box>
      <Box mt={4}>
        <Button onClick={() => history.push(`forgotpassword?userName=${username}`)} color="primary" variant="contained">
          {t("enterCode")}
        </Button>
      </Box>
    </>
  )

  const sendCode = (
    <>
      <Box width="80%" m={1}>
        <Box mt={2}>
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        </Box>
        <br />
        <Username
          initialValue={username}
          usernameIsValid={usernameIsValid}
          setUsername={setUsername} />
      </Box>


      <Box mt={2}>
        <Grid container direction="row" justify="center">
          <Box m={1}>
            <Button color="secondary" variant="contained" onClick={() => history.goBack()}>
              {t('cancel')}
            </Button>
          </Box>
          <Box m={1}>
            <Button disabled={isValid} color="primary" variant="contained" onClick={sendCodeClicked}>
              {t('sendCode')}
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  )

  return (
    <Grid className={classes.root} container direction="row" justify="center" alignItems="center">
      <Box sx={{
        display: 'inline-flex',
        top: '20px',
        position: 'absolute',
        color: 'white'
      }}>
        <img
          src={f2mlogo}
          height={'90px'}
        />
        {/*<ElectricCarIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />*/}
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="/#/"
          sx={{
            mr: 2,
            display: { xs: 'none', md: 'flex' },
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.3rem',
            color: 'inherit',
            textDecoration: 'none',
          }}
        >
          HAYLECTRIC
        </Typography>
      </Box>
      <Grid xs={11} sm={6} lg={4} container direction="row" justify="center" alignItems="center" item>
        <Paper style={{ width: '100%', padding: 32 }}>
          <Grid container direction="row" justify="center" >
            <Grid item xs={1} alignItems="center">
            </Grid>
            <Grid item xs={8} alignItems="center">
              <Typography className={classes.title}>{t('forgotPassword')}</Typography>
            </Grid>
            <Grid item xs={3} style={{ right: '10px' }}>
              <IconButton onClick={e => { changeLanguage('fr') }} sx={{ p: 0 }}>
                <Avatar alt="Francais" src={frFlag} sx={{ height: '30px', width: '30px' }} />
              </IconButton>
              <IconButton onClick={e => { changeLanguage('en') }} sx={{ p: 1 }}>
                <Avatar alt="English" src={enFlag} sx={{ height: '30px', width: '30px' }} />
              </IconButton>
            </Grid>
            {resetSent ? emailSent : sendCode}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
